import { useEffect, useState } from "react";
import { Payment, columns } from "./columns"
import { DataTable } from "./data-table"
import { fetchPost } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setPayments } from "../../../actions";
import { Urls } from "../../../utils/AppConfig";

//order/getUserPayments

export default function PaymentsTable() {
  const dispatch = useDispatch();
  const [paymentsData, setPaymentsData] = useState([]);

  useEffect(() => {
    let url = Urls.GetUserPayments;
    let token = window.localStorage.getItem('token');
    fetchPost(url, {}, token)
      .then(response => {
        console.log(response);
        if (response.error) {
          console.log("Error");
          setPaymentsData([]);
          dispatch(setPayments([]))
        } else {
          setPaymentsData(response.data)
          dispatch(setPayments(response.data))
        }
      })
      .catch(err => console.log(err));
  }, [])
  return (
    <div>
      <DataTable columns={columns} data={paymentsData} />
    </div>
  )
}
