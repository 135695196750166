"use client"

import { ColumnDef } from "@tanstack/react-table"
import * as moment from 'moment';

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { ChevronRightIcon, ChevronLeftIcon, DownloadIcon } from "@radix-ui/react-icons"

import { Button } from "../../../shadcn/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../shadcn/components/ui/dropdown-menu"


import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shadcn/components/ui/tooltip"

export const columns = [
  {
    accessorKey: "payment_id",
    header: "Payment ID",
    cell: ({ row }) => {
      return '#' + row.original['payment_id'];
    }
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => {
      return `NZ$ ${(parseFloat(row.original['amount']) / 100).toFixed(2)}`
    }
  },
  {
    accessorKey: "payment_for",
    header: "Paid for",
    cell: ({ row }) => {
      switch (row.original['payment_for']) {
        case 'report':
          return `Report (#${row.original['report_id']})`;
        case 'subscription':
          return `Subscription (#${row.original['ref_id']})`;
      }
      return row.original['payment_for']
    }
  },
  {
    accessorKey: "payment_date",
    header: "Date",
    cell: ({ row }) => {
      return moment(row.original['payment_date']).format('MMM Do, YYYY')
    }
  },
  {
    accessorKey: "payment_status",
    header: "Status",
    cell: ({ row }) => {
      return row.original['payment_status'] === 'ACTIVE' ? 'Success' : row.original['payment_status'] == 'INACTIVE' ? 'Failed' : row.original['payment_status']
    }
  }
]
