
const initialState = {
    currentParcelId: null,
    currentAddressId: null,
    currentTitleNo: null,
    addressGeometry: null,
    fullAddress: null,
    raw: {},
    user: null,
    payments: [],
    currentSubscription: null,
    returnUrl: null,
    parcelOverview: null
};

const DataReducer = (state = initialState, action) => {
    let layers = state.layers;
    switch (action.type) {
        case "SET_RETURN_URL":
            return {
                ...state,
                returnUrl: action.payload
            };
        case "UPDATE_CUR_IDS":
            return {
                ...state,
                currentParcelId: action.payload.parcelId,
                currentAddressId: action.payload.addressId,
                currentTitleNo: action.payload.titleNo,
                addressGeometry: action.payload.addressGeometry,
                fullAddress: action.payload.fullAddress
            };
        case "UPDATE_RAW":
            return {
                ...state,
                raw: {
                    ...action.payload
                }
            };
        case "SET_USER":
            let user = null;
            if (action.payload) {
                user = {
                    ...action.payload
                }
            }
            return {
                ...state,
                user: user
            };
        case "SET_PAYMENTS":
            return {
                ...state,
                payments: action.payload
            };
        case "SET_CUR_SUBSCRIPTION":
            return {
                ...state,
                currentSubscription: action.payload
            }
        case "SET_PARCEL_OVERVIEW":
            return {
                ...state,
                parcelOverview: action.payload
            }
        default:
            return {
                ...state
            };
    }
};

export default DataReducer;