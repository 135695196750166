
  import ReportTable from "./reports/page";

const ReportTab =()=>{
    return(

<div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">Reports</h3>
  <ReportTable/>
</div>
</div>

)
}
    
export default ReportTab;