import HomeHeader from "../components/HomeHeader";
import ClientProfile from "../components/Profile/ClientProfile";


const ProfilePage = (props) => {
    return <>
        <HomeHeader />
        <ClientProfile />
    </>
}

export default ProfilePage;