// import { Modal } from "@material-ui/core"
// import { Box, Typography } from "@mui/material"
import { connect, useDispatch } from "react-redux"
import { toggleAlertDialog } from "../actions"

const mapStateToProps = (state) => {
    return {
        dialog: state.DialogReducer
    }
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const AlertMessage = (props) => {
    const dispatch = useDispatch();
    return <>
        {/* <Modal
            open={Boolean(props.dialog.alertDialog.flag)}
            onClose={() => { dispatch(toggleAlertDialog(false, "", "")) }}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {props.dialog.alertDialog.title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {props.dialog.alertDialog.message}
                </Typography>
            </Box>
        </Modal> */}
    </>
}

export default connect(mapStateToProps)(AlertMessage)