
import OrderTable from "./orders/page";

const OrderTab =()=>{
    return(

<div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">Orders</h3>
  <OrderTable/>
</div>
</div>

)
}
    
export default OrderTab;