import PaymentTable from "./payments/page";

const PaymentTab =()=>{
    return(

<div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">Payments</h3>
  <PaymentTable/>
</div>
</div>

)
}
    
export default PaymentTab;