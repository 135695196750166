import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { connect } from 'react-redux';
import { Theme } from '../../utils/AppConfig';
import IcUserGreen from '../../images/ic_user_green.png';
import IcSubsGreen from '../../images/ic_subscription_green.png';
import HomeTab from './HomeTab'
import SubscriptionTab from './SubscriptionTab'
import ReportTab from './ReportTab'
import PaymentTab from './PaymentTab'
import OrderTab from './OrderTab'

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}


const ClientProfile = (props) => {

    return (
        <div className="md:mx-20 md:my-10 my-5 mx-10">
            <div>
                <Tabs
                    defaultActiveKey="home"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                    variant='pills'
                >
                    <Tab eventKey="home" title="Home">
                        <HomeTab />

                        {/* <div style={{ textAlign: 'center', padding: 16, color: '#fff', backgroundColor: 'transparent', borderRadius: 4, marginTop: 10, border: '1px solid ' + Theme.PrimaryLogo }}>
                        <HomeTab props={props}/>
                    </div> */}
                    </Tab>
                    <Tab eventKey="subscription" title="Subscription">
                        <SubscriptionTab />
                    </Tab>
                    <Tab eventKey="payments" title="Payments">
                        <PaymentTab />
                    </Tab>
                    {/* <Tab eventKey="orders" title="My Orders">
                        <OrderTab />
                    </Tab> */}
                    <Tab eventKey="reports" title="Reports">
                        <ReportTab />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}





export default connect(mapStateToProps)(ClientProfile);