import { connect, useDispatch } from "react-redux"
import { toggleAlertDialog, toggleLoader, toggleManualOrderDialog, toggleSamplePlansDialog } from "../actions"
import AddressSearch from "./AddressSearch"
import { Theme, Urls } from "../utils/AppConfig"
import { useEffect, useState } from "react"
import { fetchPost, toSlug } from "../utils/helpers";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddressInput from "./AddressInput"

const mapStateToProps = (state) => {
    return {
        dialog: state.DialogReducer,
        data: state.DataReducer
    }
}

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '800px',
    backgroundColor: '#171717',
    border: '2px solid #000',
    boxShadow: 24,
}


const OrderAutoReport = (props) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const [fullAddress, setFullAddress] = useState(props.selectedAddress ? props.selectedAddress.name : '');
    const [addressId, setAddressId] = useState(props.selectedAddress ? props.selectedAddress.id : '');
    const [openGarage, setOpenGarage] = useState('garage');
    const [houseTypes, setHouseTypes] = useState(['standalone']);
    const [noOfFloors, setNoOfFloors] = useState(['2']);
    const [selectedAddress, setSelectedAddress] = useState(props.selectedAddress);
    const [formValid, setFormValid] = useState(null);
    const [existingDwelling, setExistingDwelling] = useState('dontkeep');
    const [hasMinorDwelling, setHasMinorDwelling] = useState('hasminordwelling');
    const [isSingleHouse, setIsSingleHouse] = useState(['Residential - Large Lot Zone', 'Residential - Single House Zone', 'Residential - Rural and Coastal Settlement Zone',].includes(props.data.parcelOverview['Zoning']));

    const isMapPage = (window.location.href.indexOf("/map") > -1);
    let token = window.localStorage.getItem('token');

    const checkJobStatus = (jobId, reportId, quota) => {
        let headers = {};
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        dispatch(toggleLoader(true));
        fetch(`${Urls.GetJobStatus}?jobId=${jobId}`, { headers })
            .then(jr => jr.json())
            .then(jr => {
                console.log(jr);
                if (jr.error || jr.data['status'] === 'ERROR') {
                    dispatch(toggleLoader(false));
                    window.alert("Server error. Please, try again.");
                    return
                }
                if (jr.data['status'] === 'COMPLETED') {
                    //load plans
                    fetch(`${Urls.GetArchPlanUrls}?reportId=${reportId}`, { headers })
                        .then(jr => jr.json())
                        .then(r => {
                            dispatch(toggleLoader(false));
                            if (r.error) {
                                window.alert("Server error. Please, try again.");
                                return
                            }
                            console.log(r.data);
                            let archResult = r.data.result;
                            // let quota = r.data.quota;
                            dispatch(toggleSamplePlansDialog(true, archResult, quota));
                            dispatch(toggleManualOrderDialog(false))
                        })
                }
                if (jr.data['status'] === 'IN_PROGRESS') {
                    setTimeout(() => {
                        checkJobStatus(jobId, reportId, quota)
                    }, 2000)
                }
            })
    }

    const createOrderAndClient = async () => {
        dispatch(toggleLoader(true));
        let headers = {};
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch(`${Urls.GetArchPlans}?garage=${openGarage}&addressId=${addressId}&noOfFloors=${noOfFloors.join(',')}&houseTypes=${houseTypes.join(',')}&keepExDwellings=${existingDwelling}&hasMinorDwelling=${hasMinorDwelling}`, { headers })
            .then(r => r.json())
            .then(r => {
                console.log(r);
                // dispatch(toggleManualOrderDialog(false))
                if (r.error) {
                    dispatch(toggleLoader(false));
                    window.alert("Server error. Please, try again.");
                    return
                }
                console.log(r.data);
                checkJobStatus(r.data.jobId, r.data.reportId, r.data.quota);
                // let archResult = r.data.result;
                // let quota = r.data.quota;
                // dispatch(toggleSamplePlansDialog(true, archResult, quota));
                // dispatch(toggleManualOrderDialog(false))
            })
            .catch(e => {
                console.log(e);
                window.alert("Error")
                dispatch(toggleLoader(false));
            })
    }

    useEffect(() => {
        let flag = true;
        if (houseTypes.length === 0) {
            flag = false;
        }
        if (noOfFloors.length === 0) {
            flag = false;
        }
        if (!Boolean(selectedAddress)) {
            flag = false;
        }
        setFormValid(flag);
        if (Boolean(selectedAddress)) {
            setAddressId(selectedAddress['id']);
            setFullAddress(selectedAddress['name']);
        }
    }, [selectedAddress, houseTypes])

    return <>
        <Modal
            show={Boolean(props.dialog.manualOrderDialog.flag)}
            onHide={() => { dispatch(toggleManualOrderDialog(false)) }}
            centered
            size="lg"
            style={{
                backgroundColor: 'transparent'
            }}
        >
            <div sx={style}>
                <div id="modal-modal-description" sx={{ mt: 2 }} style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.6)',
                    backdropFilter: 'blur(5px)',
                    webkitBackdropFilter: 'blur(5px)',
                    color: '#00FF99'
                }}>
                    <div style={{ padding: 24, border: `2px solid ${Theme.PrimaryLogo}`, borderRadius: 2 }}>
                        <p className="header-2-small" style={{ textAlign: 'center' }}>
                            Auto Report
                        </p>
                        <hr style={{ color: Theme.PrimaryLogo }} />
                        <p className="content-text-details-2" style={{ fontSize: '15px !important' }}>
                            Auto-generated Report crafted by our system, incorporating data from the Unitary Plans of various districts, development restrictions, and cutting-edge GIS technology.
                        </p>


                        <label className="input-label">
                            Plot Address
                        </label>
                        <Row>
                            <Col xs={11}>
                                <AddressInput onClick={(data) => {
                                    console.log(data);
                                    setSelectedAddress(data);
                                }} defaultValue={fullAddress} />
                            </Col>
                            <Col xs={1}>
                                <div style={{ textAlign: 'right' }}>
                                    <button disabled={!Boolean(!isMapPage && selectedAddress && selectedAddress.id)} className="btn btn-small" onClick={() => {
                                        let urlSlug = selectedAddress.id + '-' + toSlug(selectedAddress.name);
                                        // window.history.replaceState(null, "Plot Potential - What Can I Do With My Plot?", `/map?q=${urlSlug}`);
                                        // navigate(`/map?q=${urlSlug}`)
                                        window.location.href = `/map?q=${urlSlug}`
                                    }}>
                                        {/* <FontAwesomeIcon icon="fa-solid fa-map" /> */}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        {/* <label className="radio-label">
                            <input type="radio" name="destroyExistingDwelling" checked={!destroyExistingDwelling} onChange={(e) => { if (e.target.checked) { setDestroyExistingDwelling(false) } }} />
                            Keep existing dwelling
                        </label>
                        <label className="radio-label">
                            <input type="radio" name="destroyExistingDwelling" checked={destroyExistingDwelling} onChange={(e) => { if (e.target.checked) { setDestroyExistingDwelling(true) } }} />
                            Destroy existing dwelling
                        </label> */}
                        {
                            isSingleHouse ? (
                                <div style={{ display: 'none' }}>
                                    <label className="input-label">
                                        Garage type
                                    </label>
                                    <Form.Select className="select-input" value="garage" disabled>
                                        <option value="garage">With Garage</option>
                                    </Form.Select>
                                </div>
                            ) : (
                                <>
                                    <label className="input-label">
                                        Garage type
                                    </label>
                                    <Form.Select
                                        className="select-input"
                                        value={openGarage === 'open_parking' ? 'open_parking' : 'garage'}
                                        onChange={e => setOpenGarage(e.target.value)}
                                    >
                                        <option value="open_parking">Open parking</option>
                                        <option value="garage">With Garage</option>
                                    </Form.Select>
                                </>
                            )
                        }
                        <br />


                        <label className="input-label">
                            House type
                        </label>
                        <br />
                        <Form.Select className="select-input" value={houseTypes} onChange={e => setHouseTypes([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                            <option value="standalone">Standalone</option>
                            {!isSingleHouse ? (<><option value="zerolot">Zerolot</option>
                                <option value="terrace">Terrace</option></>) : ''}
                        </Form.Select>

                        <br />
                        <label className="input-label">
                            No. of Floors
                        </label>
                        <br />
                        <Form.Select
                            className="select-input"
                            value={noOfFloors}
                            onChange={e => setNoOfFloors([].slice.call(e.target.selectedOptions).map(item => item.value))}
                        >
                            <option value="1">One storey</option>
                            <option value="2">Two storey</option>
                            {!isSingleHouse && <option value="3">Three storey</option>}
                        </Form.Select>

                        <br />
                        <label className="input-label">
                            Existing Dwelling
                        </label>
                        <br />
                        <Form.Select className="select-input" value={existingDwelling} onChange={e => setExistingDwelling([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                            <option value="keep">Keep</option>
                            <option value="dontkeep">Demolish</option>
                        </Form.Select>
                        {
                            isSingleHouse ? (
                                <>
                                    <br />
                                    <label className="input-label">
                                        Has Minor Dwelling?
                                    </label>
                                    <br />
                                    <Form.Select className="select-input" value={hasMinorDwelling} onChange={e => setHasMinorDwelling([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                                        <option value="hasminordwelling">Yes</option>
                                        <option value="nominordwelling">No</option>
                                    </Form.Select>
                                </>
                            ) : ''
                        }

                        <br />
                        <br />

                        <button disabled={!formValid} className="btn-inv" onClick={() => { createOrderAndClient() }}>Generate Architectural Plan</button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default connect(mapStateToProps)(OrderAutoReport);