export const layers = {
    'projectx:flood_sensitive_areas': {
        type: 'WMS',
        id: 'projectx:flood_sensitive_areas',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Flood Sensitive Areas',
        sortOrder: 1,
        minZoom: 12,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:flood_prone_areas': {
        type: 'WMS',
        id: 'projectx:flood_prone_areas',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Flood Prone Areas',
        sortOrder: 2,
        minZoom: 12,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:flood_plains': {
        type: 'WMS',
        id: 'projectx:flood_plains',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Flood Plains',
        sortOrder: 3,
        minZoom: 12,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:overland_flow_paths': {
        type: 'WMS',
        id: 'projectx:overland_flow_paths',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Overland Flowpaths',
        sortOrder: 4,
        minZoom: 16,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:water_hydrant': {
        type: 'WMS',
        id: 'projectx:water_hydrant',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Water Hydrant',
        sortOrder: 6,
        minZoom: 17,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:nz_waterpipe': {
        type: 'WMS',
        id: 'projectx:nz_waterpipe',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Water Pipes',
        sortOrder: 6,
        minZoom: 17,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:stormwater': {
        type: 'WMS',
        id: 'projectx:stormwater',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Stormwater Network',
        sortOrder: 7,
        minZoom: 16,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:wastewater': {
        type: 'WMS',
        id: 'projectx:wastewater',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Wastewater Network',
        sortOrder: 8,
        minZoom: 17,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:transmission': {
        type: 'WMS',
        id: 'projectx:transmission',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Electricity Transmission',
        sortOrder: 11,
        minZoom: 12,
        showLegend: false,
        showInLayerList: true
    },
    'projectx:electricity': {
        type: 'WMS',
        id: 'projectx:electricity',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Distribution feeders',
        sortOrder: 11,
        minZoom: 12,
        showLegend: false,
        showInLayerList: true
    },
    'projectx:geotechnical': {
        type: 'WMS',
        id: 'projectx:geotechnical',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Geotechnical',
        sortOrder: -1,
        minZoom: 12,
        showLegend: false,
        showInLayerList: true
    },
    'projectx:nz_contour_lines': {
        type: 'WMS',
        id: 'projectx:nz_contour_lines',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Contour',
        sortOrder: 12,
        minZoom: 1,
        showLegend: true,
        showInLayerList: true
    },
    'projectx:nz_title_address': {
        type: 'WMS',
        id: 'projectx:nz_title_address',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Urban Plan',
        sortOrder: 11,
        minZoom: 18,
        showLegend: false,
        showInLayerList: true
    },
    'projectx:nz_roads': {
        type: 'WMS',
        id: 'projectx:nz_roads',
        active: false,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Roads',
        sortOrder: 11,
        minZoom: 18,
        showLegend: false,
        showInLayerList: true
    },
    'projectx:nz_title_address_sat': {
        type: 'WMS',
        id: 'projectx:nz_title_address_sat',
        active: true,
        url: 'https://projectx.nz/geoserver/projectx/gwc/service/wms',
        name: 'Properties',
        sortOrder: 11,
        minZoom: 18,
        showLegend: false,
        showInLayerList: false
    },
    'satbase': {
        type: 'TILE',
        id: 'satbase',
        active: true,
        url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFuaXZhbm5hbjIzMTExOTk2IiwiYSI6ImNqd29nMndzbDF1ZDE0YW81YjRkdGJzYjAifQ.mGMG-Bq2e2OSU3GrxrBUKg',
        name: 'Satellite - Base',
        sortOrder: -2,
        showLegend: false,
        showInLayerList: false
    },
    'osmbase': {
        type: 'TILE',
        id: 'osmbase',
        active: false,
        url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFuaXZhbm5hbjIzMTExOTk2IiwiYSI6ImNqd29nMndzbDF1ZDE0YW81YjRkdGJzYjAifQ.mGMG-Bq2e2OSU3GrxrBUKg',
        name: 'Streets - Base',
        sortOrder: -3,
        showLegend: false,
        showInLayerList: false
    },
};

export const Constants = {
    // "SERVER_BASE": "https://us-central1-delta-pagoda-355210.cloudfunctions.net",
    "SERVER_BASE": "https://plotpotential.uc.r.appspot.com",
    //  "SERVER_BASE": "http://192.168.1.23:8080",
    "NZ_GEOMAPS_ESRI_SERVER": "https://mapspublic.aklc.govt.nz/arcgis/rest/services/Applications/ACWebsite/MapServer"
}

const ApiUrls = {
    Vm: {
        AddressSearch: `${Constants.SERVER_BASE}/data/searchAddress`,
        ParcelByAddress: `${Constants.SERVER_BASE}/data/getParcelDetailsByAddress`,
        ParcelByLocation: `${Constants.SERVER_BASE}/data/getParcelDetailsByLoc`,
        SalesHistory: `${Constants.SERVER_BASE}/data/getSalesHistory`,
        TitleValuation: `${Constants.SERVER_BASE}/data/getTitleValuation`,
        ParcelOverview: `${Constants.SERVER_BASE}/data/getParcelOverview`,
        AddSearchHistory: `${Constants.SERVER_BASE}/order/addSearchHistory`,
        GetReportStatus: `${Constants.SERVER_BASE}/order/getReportStatus`,
        PayForReport: `${Constants.SERVER_BASE}/order/payForReport`,
        CreateReportOrder: `${Constants.SERVER_BASE}/order/createReportOrder`,
        RequestManualReport: `${Constants.SERVER_BASE}/order/requestManualReport`,
        GetSearchHistory: `${Constants.SERVER_BASE}/data/getSearchHistory`,
        UpdateUser: `${Constants.SERVER_BASE}/auth/updateUser`,
        LoadUserSubscription: `${Constants.SERVER_BASE}/subscription/loadForUser`,
        InitiateSubscription: `${Constants.SERVER_BASE}/subscription/init`,
        GetSubscriptionPlans: `${Constants.SERVER_BASE}/subscription/getPlans`,

        SignIn: `${Constants.SERVER_BASE}/user/signIn`,
        VerifyToken: `${Constants.SERVER_BASE}/user/verifyToken`,
        PayForOrder: `${Constants.SERVER_BASE}/order/payForOrder`,

        GoogleSignInAndSignUp: `${Constants.SERVER_BASE}/auth/googleSignIn`,
        ClientSignUp: `${Constants.SERVER_BASE}/auth/signUp`,
        ClientSignIn: `${Constants.SERVER_BASE}/auth/signIn`,
        ValidateToken: `${Constants.SERVER_BASE}/auth/validateToken`,
        CreateOrder: `${Constants.SERVER_BASE}/order/create`,
        GetAllOrders: `${Constants.SERVER_BASE}/order/getAll`,
        GetUserPayments: `${Constants.SERVER_BASE}/order/getUserPayments`,
        GetUserReports: `${Constants.SERVER_BASE}/order/getUserReports`,
        CancelSubscription: `${Constants.SERVER_BASE}/subscription/cancelSubscription`,
        GetReportDetails: `${Constants.SERVER_BASE}/order/getReportDetails`,
        GetOrderPaymentStatus: `${Constants.SERVER_BASE}/order/getPaymentStatus`,
        GetSubscriptionPaymentStatus: `${Constants.SERVER_BASE}/subscription/getPaymentStatus`,

        GetArchPlans: `${Constants.SERVER_BASE}/data/getArchPlans`,
        GetJobStatus: `${Constants.SERVER_BASE}/data/getJobStatus`,
        GetArchPlanUrls: `${Constants.SERVER_BASE}/data/getArchPlanUrls`,
        DownloadMR: `${Constants.SERVER_BASE}/manualReport/download`,
        RegisterLaunch:`${Constants.SERVER_BASE}/registerLaunch`,
        RegisterDemo:`${Constants.SERVER_BASE}/registerDemo`
    }
}

export const Theme = {
    Primary: 'rgb(153, 153, 153)', //fbb454 fff323 ABE57E
    Secondary: '#00FF99',
    PrimaryLogo: '#00FF99',
    Background: '#000000aa',
    Background2: '#ffffffaa', //#2e0249aa//143f6baa

}

export const Urls = ApiUrls['Vm'];