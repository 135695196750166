import * as AppConfig from '../utils/AppConfig'

const initialState = {
    // center: [-36.902306, 174.696037],
    // center: [-37.2016, 174.8866],
    zoom: 18,
    layers: AppConfig.layers,
    mapView: {
        center: [-36.902829, 174.717313],//[-37.2016, 174.8866],
        zoom: 9
    },
    changeMapView: false,
    searchLayer: null,
    currentPage: 0,
    showLoader: false,
    loaderMessage: "Please wait..."
};

const MapReducer = (state = initialState, action) => {
    let layers = state.layers;
    switch (action.type) {
        case "TOGGLE_LOADER":
            let loaderMessage = "Please wait...";
            if (action.payload.message) {
                loaderMessage = action.payload.message;
            }
            if (!action.payload.flag) {
                loaderMessage = "Please wait...";
            }
            return {
                ...state,
                showLoader: action.payload.flag,
                loaderMessage: loaderMessage
            }
        case "SET_CUR_PAGE":
            return {
                ...state,
                currentPage: action.payload
            };
        case "CHANGE_ZOOM":
            return {
                ...state,
                zoom: action.payload
            };
        case "CHANGE_MAP_VIEW":
            return {
                ...state,
                zoom: action.payload.zoom,
                center: action.payload.center,
                mapView: {
                    zoom: action.payload.zoom,
                    center: action.payload.center
                },
                changeMapView: true
            };
        case "MAP_VIEW_WAS_SET":
            return {
                ...state,
                zoom: action.payload.zoom,
                center: action.payload.center,
                mapView: {
                    zoom: action.payload.zoom,
                    center: action.payload.center
                },
                changeMapView: false
            };
        case "TOGGLE_LAYER_STATE":
            layers[action.payload.layerId].active = action.payload.active;
            return {
                ...state,
                layers: layers
            }
        case "ADD_LAYER":
            layers[action.payload.id] = action.payload;
            return {
                ...state,
                layers: layers
            }
        case "ADD_SEARCH_LAYER":
            if (Boolean(state.searchLayer) && Boolean(layers[state.searchLayer])) {
                delete layers[state.searchLayer];
            }
            layers[action.payload.layerId] = action.payload.data;
            return {
                ...state,
                searchLayer: action.payload.layerId,
                layers: { ...layers }
            }
        case "REMOVE_SEARCH_LAYER":
            if (Boolean(state.searchLayer) && Boolean(layers[state.searchLayer])) {
                delete layers[state.searchLayer];
            }
            return {
                ...state,
                searchLayer: null,
                layers: { ...layers }
            }
        case "TOGGLE_BASEMAP":
            let _layers = { ...state.layers };
            if (action.payload === 'custom') {
                _layers['satbase'].active = false;
                _layers['osmbase'].active = true;
                // _layers['projectx:nz_title_address_sat'].active = false;
                // _layers['projectx:nz_title_address'].active = true;
            } else {
                _layers['satbase'].active = true;
                _layers['osmbase'].active = false;
                // _layers['projectx:nz_title_address_sat'].active = true;
                // _layers['projectx:nz_title_address'].active = false;
            }
            return {
                ...state,
                layers: { ..._layers }
            }
        default:
            return {
                ...state
            };
    }
};

export default MapReducer;